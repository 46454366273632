import { Link as RemixLink, type LinkProps } from 'react-router'
import { forwardRef } from 'react'
import { getHref } from './link.helper'
import { useClientLang } from '~/utils/i18n.utils'

export const Link = forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => {
	const lang = useClientLang()

	return (
		<RemixLink {...props} ref={ref} to={getHref(props.to, lang.lngShort)}>
			{props.children}
		</RemixLink>
	)
})

Link.displayName = 'Link'
